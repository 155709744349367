.slide-right {
  transform: translateX(100%); /* Start off-screen to the right */
  animation: slideRight 1s forwards; /* Slide Right animation */
}

@keyframes slideRight {
  to {
      transform: translateX(0); /* Move to original position */
  }
}

.slide-up {
  transform: translateY(100%); /* Start off-screen */
  opacity: 0; /* Start with opacity 0 */
  transition: transform 0.5s ease-out, opacity 0.5s ease-out; /* Smooth transition */
}

.slide-up.visible {
  transform: translateY(0); /* Move to original position */
  opacity: 1; /* Final opacity */
}

.fade-in {
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.5s ease-in; /* Smooth transition */
}

.fade-in.visible {
  opacity: 1; /* Final opacity */
}


.section {
  margin-top:60px ;
  margin-bottom:30px ;
}


.loader, .loader:before, .loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
  color: #FFF;
  font-size: 7px;
  position: absolute;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  top: 0;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%, 80%, 100% { box-shadow: 0 2.5em 0 -1.3em }
  40% { box-shadow: 0 2.5em 0 0 }
}
    
.responsive-item {
  width: 100%; /* Default for extra-small screens */
  margin: 8px; /* Margin between items */
}

@media (min-width: 600px) {
  .responsive-item {
      width: 45%; /* For small and medium screens */
  }
}

@media (min-width: 960px) {
  .responsive-item {
      width: 30%; /* For large screens */
  }
}
